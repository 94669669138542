import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/iRGSCV88RYI">
    <SEO title="An Interlude of Praise - Romans: Practice" />
  </Layout>
)

export default SermonPost
